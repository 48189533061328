.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    overflow: hidden;
    line-height: 15px;
    padding: 10px;
  }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: #e6e6e6;
    border-radius: 100px;
  }
  
  .react-calendar__navigation button:enabled:hover {
    background-color: #e6e6e6;
    border-radius: 100px;
  }

  .react-calendar__navigation__label__labelText {
      font-size: 16px;
      font-family: "bold";
  }

  .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font: inherit;
    }

    .react-calendar__month-view__weekdays abbr {
        font-size: 16px;
    }
    
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekdays__weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 16px;
    font-weight: bold;
  }

  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
  }

  .react-calendar__month-view__days__day {
    aspect-ratio: 1 / 1;
  }

  .react-calendar__tile abbr {
    font-size: 18px;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__month-view__days__day:enabled:hover,
  .react-calendar__month-view__days__day:enabled:focus {
      border-radius: 100px;
      box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.20);
    }
  
  
  .react-calendar__tile--hasActive {
    background: rgb(14, 183, 233);
  }
  
  
  .react-calendar__tile--active {
    background: rgb(14, 183, 233);
    border-radius: 100px;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: rgb(14, 183, 233);
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  