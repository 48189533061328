
  
  /* Gradient Range Slider */
  .range-slider-gradient {
    border-radius: 12px;
    /* background: rgb(196,196,196); */
  }
  
  .range-slider-gradient .range-slider__thumb {
    box-sizing: border-box;
  }
  
  .range-slider-gradient .range-slider__thumb[data-lower] {
    background: rgba(240, 0, 255);
  }
  
  .range-slider-gradient .range-slider__thumb[data-upper] {
    background: rgba(240, 0, 255);
  }
  
  .range-slider-gradient .range-slider__range {
    background: rgb(14, 183, 233);
  }
  
  .range-slider-gradient .range-slider__range[data-active],
  .range-slider-gradient
    .range-slider__thumb[data-active]
    ~ .range-slider__range {
    animation: move-bg 0.75s infinite linear;
  }
  
  @keyframes move-bg {
    0% {
      background-position: 50% 0;
    }
  
    25% {
      background-position: 100% 0;
    }
  
    50% {
      background-position: 50% 0;
    }
  
    75% {
      background-position: 0% 0;
    }
  
    100% {
      background-position: 50% 0;
    }
  }
  
  /* Range Slider as a Single Thumb Slider */
  .single-thumb .range-slider__thumb[data-lower] {
      width: 0;
  }
  .single-thumb .range-slider__thumb {
    background-color: rgba(240, 0, 255);
  }
  .single-thumb .range-slider__range {
    border-radius: 6px;
    background-color: rgb(14, 183, 233);
  }

  .range-slider .range-slider__thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(240, 0, 255);
    cursor: pointer;
    transition: box-shadow 0.2s;
    outline: none;
  }

  .range-slider {
    height: 3px;
  }
  
  