.fc-h-event {
    border: none;
    cursor: pointer;
    border-radius: 100px;
    &:not(.fc-event-start) {
        * {
            visibility: hidden;
        }
    }
}

.fc-event-start {
    border-radius: 50px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    overflow: hidden;
}

.fc-event:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 5px;
}

.fc-toolbar-title {
    font-size: 1rem !important;
    font-weight: 600;
}

.fc-header-toolbar .fc-button {
    background-color: #f8f9fa !important;
    border: none !important;
    color: #495057 !important;
    padding: 0.375rem 0.75rem;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: color 0.15s;
}

.fc-header-toolbar .fc-button:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.fc-header-toolbar .fc-button.fc-button-active {
    background-color: rgb(14, 183, 233) !important;
    border: none !important;
    color: white !important;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(240, 0, 255,0.75) !important;
    color: white ;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    color: white !important;
}

.fc-header-toolbar .fc-button.fc-today-button {
    color: rgba(240, 0, 255,0.75) !important;
}

.fc-daygrid-day-top {
    height: 25px;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 3px;
}

.fc-col-header-cell-cushion, .fc-toolbar-title, .fc-daygrid-day-number, .fc-icon {
    color: #495057;
    fill: #495057;
}

@media (max-width: 720px) {

    .fc-toolbar-ltr .fc-toolbar-chunk:nth-child(1) {
        display: none;
    }
}