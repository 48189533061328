@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'extrabold';
  src: local('Urbanist-Bold'), url(./assets/font/Urbanist/Urbanist-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'light';
  src: local('Urbanist-Light'), url(./assets/font/Urbanist/Urbanist-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'extralight';
  src: local('Urbanist-Extra-Light'), url(./assets/font/Urbanist/Urbanist-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'bold';
  src: local('Urbanist-Medium'), url(./assets/font/Urbanist/Urbanist-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'regular';
  src: local('Urbanist-Regular'), url(./assets/font/Urbanist/Urbanist-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'thin';
  src: local('Urbanist-Thin'), url(./assets/font/Urbanist/Urbanist-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'thin-italic';
  src: local('Urbanist-Thin-Italic'), url(./assets/font/Urbanist/Urbanist-ThinItalic.ttf) format('truetype');
}

// :root {
//   --fc-page-bg-color: #fff;
//   --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
//   --fc-neutral-text-color: #808080;
//   --fc-border-color: #ddd;

//   --fc-button-text-color: #fff;
//   --fc-button-bg-color: #2c3e50;
//   --fc-button-border-color: #2c3e50;
//   --fc-button-hover-bg-color: #1e2b37;
//   --fc-button-hover-border-color: #1a252f;
//   --fc-button-active-bg-color: #1a252f;
//   --fc-button-active-border-color: #151e27;

//   --fc-event-bg-color: #3788d8;
//   --fc-event-border-color: #3788d8;
//   --fc-event-text-color: #fff;
//   --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

//   --fc-more-link-bg-color: #d0d0d0;
//   --fc-more-link-text-color: inherit;

//   --fc-event-resizer-thickness: 8px;
//   --fc-event-resizer-dot-total-width: 8px;
//   --fc-event-resizer-dot-border-width: 1px;

//   --fc-non-business-color: rgba(215, 215, 215, 0.3);
//   --fc-bg-event-color: rgb(143, 223, 130);
//   --fc-bg-event-opacity: 0.3;
//   --fc-highlight-color: rgba(188, 232, 241, 0.3);
//   --fc-today-bg-color: rgba(255, 220, 40, 0.15);
//   --fc-now-indicator-color: red;
// }


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: none;
}

@media (max-width: 720px) {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(14, 183, 233);
  border-radius: 5px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

// .range-sm {
//   height: 1.00rem/* 20px */;
// }
// .range-sm::-webkit-slider-runnable-track {
//   height: 0.10rem/* 4px */;
// }
// .range-sm::-moz-range-track {
//   height: 0.10rem/* 4px */;
// }
// .range-sm::-webkit-slider-thumb {
//   height: 1.00rem/* 20px */;
//   width: 1.00rem/* 20px */;
//   --filler-offset: 0.5rem/* 8px */;
// }
// .range-sm::-moz-range-thumb {
//   height: 1.00rem/* 20px */;
//   width: 1.00rem/* 20px */;
//   --filler-offset: 0.5rem/* 8px */;
// }

div[data-testid="uc-privacy-icon"] {
  display: none;
}