.legal-document {
  h1 {
    font-size: 1.5rem;
    font-family: bold;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1rem;
    font-family: bold;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 0.9rem;
    font-family: bold;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 0.75rem;
    font-family: bold;
    margin-bottom: 1rem;
  }

  p,
  a {
    margin-bottom: 1rem;
    font-size: 0.75rem;
  }

  a:hover {
    text-decoration: underline;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 1rem;
    li {
      font-size: 0.75rem;
    }
  }
}
